const nationalFreightBillingData = [
    {
        label: "Vendemmia",
        value: "VENDEMMIA",
    },
    {
        label: "Cliente",
        value: "CLIENTE",
    }
];

export default nationalFreightBillingData;